import React, { useState, useEffect } from "react"

const DataTable = () => {
  const [scans, setScans] = useState([]) // State to hold scan data

  // Fetch scan data from the server
  useEffect(() => {
    fetch("https://dashboard-app-1.onrender.com/api/scans")
      .then((response) => response.json())
      .then((data) => setScans(data))
      .catch((error) => console.error("Error fetching data:", error))
  }, []) // Empty dependency array to fetch data only once on mount

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Scan Data Table</h2>
      <table className="min-w-full bg-white shadow-md rounded overflow-hidden">
        <thead>
          <tr>
            <th className="px-6 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold">
              User ID
            </th>
            <th className="px-6 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold">
              Location
            </th>
            <th className="px-6 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold">
              Device Info
            </th>
            <th className="px-6 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold">
              Referrer URL
            </th>
            <th className="px-6 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold">
              Timestamp
            </th>
          </tr>
        </thead>
        <tbody>
          {scans.map((scan) => (
            <tr key={scan.id}>
              <td className="px-6 py-4 border-b border-gray-200">
                {scan.user_id}
              </td>
              <td className="px-6 py-4 border-b border-gray-200">
                {scan.location}
              </td>
              <td className="px-6 py-4 border-b border-gray-200">
                {scan.device_info}
              </td>
              <td className="px-6 py-4 border-b border-gray-200">
                {scan.referrer_url}
              </td>
              <td className="px-6 py-4 border-b border-gray-200">
                {new Date(scan.scan_timestamp).toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default DataTable
