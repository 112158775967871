// src/Components/ScanChart.jsx
import React, { useState, useEffect } from "react"
import { Line } from "react-chartjs-2"
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js"

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
)

const ScanChart = () => {
  const [chartData, setChartData] = useState({}) // State for chart data

  useEffect(() => {
    fetch("https://dashboard-app-1.onrender.com/api/scans")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then((data) => {
        // Transform the data into a format suitable for the chart
        const timestamps = data.map((scan) =>
          new Date(scan.scan_timestamp).toLocaleDateString()
        )
        const counts = timestamps.reduce((acc, date) => {
          acc[date] = (acc[date] || 0) + 1
          return acc
        }, {})

        setChartData({
          labels: Object.keys(counts),
          datasets: [
            {
              label: "Scans Over Time",
              data: Object.values(counts),
              borderColor: "rgba(75, 192, 192, 1)",
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderWidth: 1,
            },
          ],
        })
      })
      .catch((error) => console.error("Error fetching chart data:", error))
  }, [])

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Scan Data Chart</h2>
      {chartData.labels ? (
        <Line data={chartData} />
      ) : (
        <p>Loading chart data...</p>
      )}
    </div>
  )
}

export default ScanChart
