import React from "react"
import DataTable from "./Components/DataTable"
import ScanChart from "./Components/ScanChart"

const App = () => {
  return (
    <div className="container mx-auto">
      <h1 className="text-4xl font-bold text-center py-6">QR Scan Dashboard</h1>
      <ScanChart />
      <DataTable />
    </div>
  )
}

export default App
